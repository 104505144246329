import AllEvents from 'components/eventpage/AllEvents';
import React from 'react';
import Layout from 'styles/Layout';

const EventPage: React.FC = () => {
  return (
    <Layout padding='0 18%' mobilePadding='0 5%'>
      <AllEvents />
    </Layout>
  );
};

export default EventPage;
