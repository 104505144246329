/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import sanityClient from 'sanityClient';
import styled from 'styled-components';
import { Body } from 'types/types';
import BlockContent from '@sanity/block-content-to-react';
import { Fade } from 'react-awesome-reveal';
import { pdfjs, Document, Page } from 'react-pdf';
import { SizeMe } from 'react-sizeme';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/styles.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
interface AboutContentProps {
  listOfTitles: string[];
  listOfDescription: Body[];
  pdfURL?: string[];
}

const ContentContainer = styled.div`
  margin: 40px 0;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.lightPurple};
  max-width: 100%;
  @media (max-width: 768px) {
    margin: 2% 5%;
  }
`;

const StyledPage = styled(Page)`
  margin: 10px 0;
`;

const TextContainer = styled.div`
  margin: 0 15% 8% 15%;
  @media (max-width: 576px) {
      margin: 0 8% 8% 8%;;
    }
  a {
    word-break: break-all;
  }
  img {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    @media (max-width: 576px) {
      max-width: 100%;
      min-width: 190px;
    }
  }
`;

const StyledUL = styled.ul`
  border-radius: 5px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
`;

const StyledLI = styled.li`
  float: left;
  &.active {
    background-color: ${({ theme }) => theme.purple};
    color: white;
  }
`;

const StyledText = styled.a`
  display: block;
  text-align: center;
  padding: 20px 30px;
  text-decoration: none;
  cursor: pointer;
  font-size: 16px;

  @media (max-width: 768px) {
    padding: 20px 25px;
    font-size: 15px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.purple};
    color: white;
  }
`;

const AboutContent: React.FC<AboutContentProps> = ({
  listOfTitles,
  listOfDescription,
  pdfURL,
}) => {
  const [toggleIndex, setToggleIndex] = useState<number>(0);
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages);
  }

  const toggleIndexDiv = (i: number) => {
    setToggleIndex(i);
  };

  return (
    <>
      <Fade cascade damping={0.2} duration={1000} delay={200} triggerOnce>
        <ContentContainer>
          <StyledUL>
            {listOfTitles &&
              listOfTitles.map((title, i) => (
                <StyledLI className={toggleIndex === i ? "active" : "inactive"} key={title} onClick={() => toggleIndexDiv(i)}>
                  <StyledText>{title}</StyledText>
                </StyledLI>
              ))}
          </StyledUL>
        </ContentContainer>
      </Fade>
      <TextContainer>
        {listOfDescription &&
          listOfDescription.map((description) => (
            <Fade cascade damping={0.2} duration={1000} delay={200} triggerOnce>
              <BlockContent
                blocks={description}
                projectId={sanityClient.config().projectId}
                dataset={sanityClient.config().dataset}
              />
            </Fade>
          ))[toggleIndex]}
        {pdfURL &&
          pdfURL.map((link) => (
            link ? (
              <>
                <AwesomeButton type='secondary' target='_blank' href={link}>Last ned PDF</AwesomeButton>
                <SizeMe>
                  {/* For scaling the pdf */}
                  {({ size }) => (
                    <Document
                      file={{ url: link }}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      {
                        Array.from(
                          new Array(numPages),
                          (el, index) => (
                            <StyledPage
                              width={size.width ? size.width : 1}
                              key={`page_${index + 1}`}
                              pageNumber={index + 1}
                            />
                          ),
                        )
                      }
                    </Document>
                  )}
                </ SizeMe>
              </>
            ) : (<></>)
          ))[toggleIndex]}
      </TextContainer>
    </>
  );
};

export default AboutContent;
