import React from 'react';
import styled from 'styled-components';
import { DescriptionText, UnderlinedBoldTitle } from 'styles/Text';
import { Slug, Body } from 'types/types';
import { norwegianDays, norwegianMonths } from './HelperLists';

interface EventTypes {
  title: string;
  slug?: Slug;
  eventDate: string;
  place?: string;
  eventImage?: string;
  eventAlt?: string;
  description?: Body;
  facebookUrl?: string;
  categories?: string;
  publishedTime?: number;
  cardColor: string;
  recurring?: boolean;
  underlineColor?: string;
}

interface CardTypes {
  backgroundColor: string;
}

const TextBox = styled.div`
  flex: 1;
  margin: 5px 0 5px 15px;
  flex-basis: calc(170px);
  /* flex-basis: calc(576px / 2 - 15px); // Wraps at 576px, 576/2 px -15px */
`;

const LinkText = styled.p`
  margin: 20px 0 0 0;
  color: ${({ theme }) => theme.grey};
  font-size: 15px;
  display: inline-block;

  &:hover {
    transition: 0.5s all;
    color: rgba(0, 0, 0, 0.4);
  }
  @media (max-width: 768px) {
    font-size: 13px;
  }
`;

const ImageBox = styled.div`
  flex: 1;
  margin: 5px 15px 5px 0;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 576px) {
    justify-content: center;
    margin: 5px 5px;
  }
`;

const EventImage = styled.img`
  display: block;
  max-width: 80%;
  min-width: 250px;
  object-fit: contain;
  border-radius: 5px;
  @media (max-width: 576px) {
    max-width: 100%;
  }
`;

const CardContainer = styled.div`
  display: flex;
  background-color: ${(props: CardTypes) => props.backgroundColor || 'white'};
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
  flex-wrap: wrap;
  z-index: 10;
`;

const EventCard: React.FC<EventTypes> = ({
  title,
  eventDate,
  recurring,
  place,
  eventImage,
  cardColor,
  underlineColor,
  eventAlt,
  // categories,
}) => {
  const dateFormatter = () => {
    const unixTime = Date.parse(eventDate);
    const dateObject = new Date(unixTime);
    const date = `0${dateObject.getDate()}`.slice(-2);
    let dayString = norwegianDays[dateObject.getDay()];
    const month = norwegianMonths[dateObject.getMonth()];
    if (recurring) {
      dayString = dayString.toLowerCase();
      return `Hver ${dayString}`;
    }
    return `${dayString} ${date}. ${month}`;
  };

  return (
    <CardContainer backgroundColor={cardColor}>
      <TextBox>
        <UnderlinedBoldTitle
          fontSize='20px'
          mobileMidFontSize='16px'
          margin='10px 10px 20px 0'
          size='2px' // border size
          color={underlineColor}
        >
          {title}
        </UnderlinedBoldTitle>
        <DescriptionText>
          <b>Når:</b> {dateFormatter()}
        </DescriptionText>
        <DescriptionText>
          <b>Hvor:</b> {place}
        </DescriptionText>
        <LinkText>Se mer {'>'}</LinkText>
      </TextBox>
      <ImageBox>
        <EventImage src={eventImage} alt={eventAlt} />
      </ImageBox>
    </CardContainer>
  );
};

export default EventCard;
