import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Layout from 'styles/Layout';
import { UnderlinedBoldTitle } from 'styles/Text';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AwesomeButtonSocial } from 'react-awesome-button';
import 'react-awesome-button/dist/styles.css';
import { Fade } from 'react-awesome-reveal';
import sanityClient from 'sanityClient';
import { InstagramLinkData } from 'types/types';
import Loading from 'components/Loading';

const ContentContainer = styled.div`
  margin: 20px 0 40px 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  border-top: 5px dotted ${({ theme }) => theme.lightPurple};
  border-radius: 5px;
`;

const StyledIframe = styled.iframe`
  /* Custom hard coded responsiveness */
  margin: 10px 5px;
  height: 410px;
  width: 312px;

  @media (max-width: 1100px) {
    height: 360px;
    width: 262px;
  }

  @media (max-width: 905px) {
    height: 300px;
    width: 202px;
  }

  @media (max-width: 576px) {
    height: 320px;
    width: 322px;
  }

  @media (max-width: 376px) {
    height: 300px;
    width: 302px;
  }
`;

const Buttons = styled.div`
  flex-wrap: wrap;
  display: flex;
  margin: 0;
`;

const ButtonContainer = styled.div`
  margin: 5px 5px 5px 0;
`;

const GalleryPage: React.FC = () => {
  const [instagramLinks, setInstagramLinks] = useState<InstagramLinkData[]>();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "gallery"] | order(index) {
          index,
          instagramLink
        }`,
      ) // Fetches and finds the nearest event in time
      .then((data) => {
        setInstagramLinks(data);
      });
  }, []);

  if (!instagramLinks)
    return (
      <Layout>
        <Loading />
      </Layout>
    );

  return (
    <Layout padding='0 20%' mobilePadding='2% 5%'>
      <Fade cascade damping={0.2} duration={1000} delay={500} triggerOnce>
        <UnderlinedBoldTitle margin='10px 0 20px 0' mobileFontSize='25px'>
          Følg oss på sosiale medier!
        </UnderlinedBoldTitle>
        <Buttons>
          <ButtonContainer>
            <AwesomeButtonSocial
              type='instagram'
              href='https://www.instagram.com/psi.ntnu/?hl=en'
              target='_blank'
              size='large'
            >
              Instagram
            </AwesomeButtonSocial>
          </ButtonContainer>
          <ButtonContainer>
            <AwesomeButtonSocial
              type='facebook'
              href='https://www.facebook.com/psi.linjeforening'
              target='_blank'
              size='large'
            >
              Facebook
            </AwesomeButtonSocial>
          </ButtonContainer>
        </Buttons>
        <ContentContainer>
          {instagramLinks &&
            instagramLinks
              .map((post) => (
                <div>
                  <StyledIframe
                    title='insta'
                    src={`${post.instagramLink}embed`}
                    frameBorder='0'
                    scrolling='no'
                  />
                </div>
              ))
              .reverse()}
        </ContentContainer>
      </Fade>
    </Layout>
  );
};

export default GalleryPage;
