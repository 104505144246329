import AboutContent from 'components/aboutpage/AboutContent';
import Loading from 'components/Loading';
import React, { useEffect, useState } from 'react';
import sanityClient from 'sanityClient';
import Layout from 'styles/Layout';
import { SanityData } from 'types/types';

const AboutPage: React.FC = () => {
  const [aboutData, setAboutData] = useState<SanityData[]>();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "about"] | order(index) {
        title,
        description,
        index
      }`,
      )
      .then((data) => {
        setAboutData(data);
      });
  }, []);

  if (!aboutData)
    return (
      <Layout>
        <Loading />
      </Layout>
    );

  return (
    <Layout padding='0 10%' mobilePadding='0'>
      <AboutContent
        listOfTitles={aboutData.map((x) => x.title)}
        listOfDescription={aboutData.map((x) => x.description)}
      />
      {/* Send list of all titles */}
    </Layout>
  );
};

export default AboutPage;
