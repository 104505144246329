import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import sanityClient from 'sanityClient';
import styled from 'styled-components';
import { BoldTitle } from 'styles/Text';
import { SanityAllEventsData } from 'types/types';
import { Fade } from 'react-awesome-reveal';
import Loading from 'components/Loading';
import EventCard from './EventCard';

const CardLink = styled(Link)`
  text-decoration: none;
`;

const EventsContainer = styled.div`
  margin-bottom: 40px;
`;

const EventTitle = styled(BoldTitle)`
  margin: 30px 0 20px 0;
  font-size: 26px;

  @media (max-width: 576px) {
    margin: 40px 0 20px 5px;
    font-size: 23px;
  }
`;

const AllEvents: React.FC = () => {
  const [allEventsData, setAllEvents] = useState<SanityAllEventsData[]>();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "events"]{
        slug,
        title,
        eventDate,
        place,
        priority,
        recurring,
        eventImage {
          alt,
          asset -> {
          _id,
          url
        },
        categories,
      }
    }`,
      )
      .then((data) => {
        setAllEvents(data);
      });
  }, []);

  if (!allEventsData) return <Loading />;

  return (
    <EventsContainer>
      <Fade direction='up' damping={0.2} delay={200} triggerOnce>
        <EventTitle>Kommende arrangementer</EventTitle>
      </Fade>
      <div>
        {allEventsData &&
          allEventsData
            .filter(
              (x) =>
                x.priority || new Date(Date.parse(x.eventDate)) > new Date(),
            )
            // Filtrer bort gamle arrangementer
            .sort(
              (a, b) =>
                new Date(a.eventDate).getTime() -
                new Date(b.eventDate).getTime(),
            )
            // Sorter etter nyeste arrangement
            .map((post) => (
              <CardLink
                to={`/arrangementer/${post.slug.current}`}
                key={post.slug.current}
              >
                <Fade
                  direction='up'
                  cascade
                  damping={0.2}
                  delay={200}
                  triggerOnce
                >
                  <EventCard
                    title={post.title}
                    eventImage={post.eventImage.asset.url}
                    eventAlt={post.eventImage.alt}
                    eventDate={post.eventDate}
                    place={post.place}
                    cardColor='#E9E4F3'
                    recurring={post.recurring}
                  />
                </Fade>
              </CardLink>
            ))}
      </div>
      <Fade direction='up' damping={0.2} delay={400} triggerOnce>
        <EventTitle>Tidligere arrangementer</EventTitle>
      </Fade>
      <div>
        {allEventsData &&
          allEventsData
            .filter(
              (x) =>
                !x.priority && new Date(Date.parse(x.eventDate)) < new Date(),
            )
            // Filtrer bort nye arrangementer
            .sort(
              (a, b) =>
                new Date(b.eventDate).getTime() -
                new Date(a.eventDate).getTime(),
            )
            // Sorter etter nyeste arrangement
            .slice(0, 5)
            // Vis de fem siste arrangementene
            .map((post) => (
              <CardLink
                to={`/arrangementer/${post.slug.current}`}
                key={post.slug.current}
              >
                <Fade direction='up' cascade damping={0.2} triggerOnce>
                  <EventCard
                    title={post.title}
                    eventImage={post.eventImage.asset.url}
                    eventDate={post.eventDate}
                    eventAlt={post.eventImage.alt}
                    place={post.place}
                    cardColor='#E5E5E5'
                    recurring={post.recurring}
                    underlineColor='#50514F'
                  />
                </Fade>
              </CardLink>
            ))}
      </div>
    </EventsContainer>
  );
};

export default AllEvents;
