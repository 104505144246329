import React from 'react';
import styled from 'styled-components';
import { ReactComponent as PsiOnlyLogo } from 'assets/images/psi-only-logo.svg';

const AnimatedMail = styled.div`
  position: absolute;
  height: 150px;
  width: 200px;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  transition: 0.4s;
`;

const BackFold = styled.div`
  position: absolute;
  bottom: 0;
  width: 200px;
  height: 100px;
  background: #f3878c;
  z-index: 0;
`;

const Letter = styled.div`
  left: 20px;
  bottom: 0px;
  position: absolute;
  width: 160px;
  height: 60px;
  background: white;
  z-index: 1;
  overflow: hidden;
  transition: 0.4s 0.2s;
`;

const LetterBorder = styled.div`
  height: 10px;
  width: 100%;
  background: repeating-linear-gradient(
    -45deg,
    #f16a70,
    #f16a70 8px,
    transparent 8px,
    transparent 18px
  );
`;

const TopFold = styled.div`
  position: absolute;
  top: 50px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 100px 0 100px;
  transform-origin: 50% 0%;
  transition: transform 0.4s 0.4s, z-index 0.2s 0.4s;
  border-color: #c05459 transparent transparent transparent;
  z-index: 2;
`;

const LetterBody = styled.div`
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 100px 200px;
  border-color: transparent transparent #f2787e transparent;
  z-index: 2;
`;

const LeftFold = styled.div`
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 0 50px 100px;
  border-color: transparent transparent transparent #f16a70;
  z-index: 2;
`;

const LetterImage = styled.div`
  width: 200px;
  height: 200px;
  cursor: pointer;

  :hover ${AnimatedMail} {
    transform: translateY(30px);
  }

  :hover ${AnimatedMail} ${TopFold} {
    transition: transform 0.4s, z-index 0.2s;
    transform: rotateX(180deg);
    z-index: 0;
  }

  :hover ${AnimatedMail} ${Letter} {
    height: 150px;
  }
`;

const AnimatedPsiOnlyLogo = styled(PsiOnlyLogo)`
  max-width: 200px;
  margin: 10px 50px 0 50px;
  fill: rgba(0, 0, 0, 0);
  fill-rule: evenodd;
  stroke: black;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 681%, 1000%;

  /* Animation */
  @keyframes dash {
    0% {
      stroke-dasharray: 0%, 1000%;
    }
    70% {
      stroke-dasharray: 681%, 1000%;
      fill: rgba(0, 0, 0, 0);
    }
    80% {
      fill: rgba(0, 0, 0, 255);
    }
    90% {
      fill: rgba(0, 0, 0, 0);
    }
    100% {
      stroke-dasharray: 0%, 1000%;
    }
  }
  animation-name: dash;
  animation-duration: 5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
`;

const MailLetter = () => {
  return (
    <LetterImage>
      <AnimatedMail>
        <BackFold />
        <Letter>
          <LetterBorder />
          <AnimatedPsiOnlyLogo />
        </Letter>
        <TopFold />
        <LetterBody />
        <LeftFold />
      </AnimatedMail>
    </LetterImage>
  );
};

export default MailLetter;
