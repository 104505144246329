import styled from 'styled-components';

interface TitleProps {
  fontSize?: string;
  mobileFontSize?: string;
  mobileMidFontSize?: string;
  margin?: string;
  width?: string;
  textAlign?: string;
  color?: string;
  fontWeight?: string;
  left?: string;
  marginLeft?: string;
  size?: string;
}

export const BoldTitle = styled.p`
  font-size: ${(props: TitleProps) => props.fontSize || '30px'};
  font-weight: bolder;
  margin: ${(props: TitleProps) => props.margin || '0'};
  color: ${({ theme }) => theme.black};
  @media (max-width: 768px) {
    font-size: ${(props: TitleProps) => props.mobileMidFontSize || '25px'};
  }
  @media (max-width: 576px) {
    font-size: ${(props: TitleProps) => props.mobileFontSize || '20px'};
  }
  position: relative;
`;

export const UnderlinedBoldTitle = styled(BoldTitle)`
  cursor: pointer;
  :after {
    content: '';
    position: absolute;
    top: 110%;
    margin: 0;
    // For centering underline element, left 50%
    left: ${(props: TitleProps) => props.left || '0%'};
    margin-left: ${(props: TitleProps) => props.marginLeft || '0'};
    //For centering underline element, margin left width / 2
    width: 40px;
    border-bottom: ${(props: TitleProps) => props.size || '4px'} solid
      ${(props: TitleProps) => props.color || '#CF4ECF'};
    border-radius: 5px;
    transition: width 0.3s ease;
  }

  :hover::after {
    width: 0%;
  }
`;

export const SubTitle = styled.p`
  font-size: ${(props: TitleProps) => props.fontSize || '20px'};
  margin: ${(props: TitleProps) => props.margin || '5px 0 5px 0'};
  color: ${({ theme }) => theme.grey};
  font-weight: ${(props: TitleProps) => props.fontWeight || '500'};
  @media (max-width: 1024px) {
    font-size: ${(props: TitleProps) => props.mobileMidFontSize || '16px'};
  }
`;

export const AnchorText = styled.a`
  color: ${(props: TitleProps) => props.color || 'white'};

  &:hover {
    text-decoration: unset;
  }
`;

export const DescriptionText = styled.p`
  margin: ${(props: TitleProps) => props.margin || '0 10px 3px 0'};
  color: black;
  font-size: 15px;

  @media (max-width: 768px) {
    font-size: 13px;
  }
`;
