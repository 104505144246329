import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import sanityClient from 'sanityClient';
import styled from 'styled-components';
import { BoldTitle, DescriptionText, UnderlinedBoldTitle } from 'styles/Text';
import { SanityOneEventData } from 'types/types';
import BlockContent from '@sanity/block-content-to-react';
import { Fade } from 'react-awesome-reveal';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AwesomeButtonSocial } from 'react-awesome-button';
import 'react-awesome-button/dist/styles.css';
import { FaArrowLeft } from 'react-icons/fa';
import Loading from 'components/Loading';
import { norwegianDays, norwegianMonths } from './HelperLists';

const EventsContainer = styled.div`
  margin-bottom: 40px;
`;

const BackButton = styled.div`
  cursor: pointer;
  font-weight: bolder;
  display: inline-block;
  margin: 10px 0 0 5px;
  color: ${({ theme }) => theme.grey};

  &:hover::after {
    text-decoration: underline;
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  &::after {
    border-radius: 2px;
    content: '';
    display: inline-block;
    width: 100%;
    border-top: 2px solid;
    margin-bottom: 10px;
    opacity: 0;
    transition: opacity 0.35s, transform 0.35s;
    transform: scale(0, 1);
  }
`;

const ImageBox = styled.div`
  flex: 1;
  margin: 5px 0;
  display: flex;
  justify-content: center;
  @media (max-width: 576px) {
    justify-content: center;
  }
`;

const EventImage = styled.img`
  /* may be reused */
  display: block;
  max-width: 100%;
  min-width: 250px;
  object-fit: contain;
  border-radius: 5px;
`;

const EventInfoContainer = styled.div`
  margin-top: 20px;
  background-color: ${({ theme }) => theme.lightPurple};
  border-radius: 5px;
  padding: 20px;
`;

const DescriptionContainer = styled.div`
  margin-top: 20px;
  font-size: 16px;
  @media (max-width: 768px) {
    padding: 0 10px;
    font-size: 14px;
  }
`;

const SocialContainer = styled.div`
  @media (max-width: 768px) {
    padding: 0 10px;
  }
`;

const SingleEvent: React.FC = () => {
  const [eventData, setEventData] = useState<SanityOneEventData>();
  const { slug } = useParams<{ slug: string }>();
  const history = useHistory();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == "${slug}"]{
        title,
        slug,
        eventDate,
        place,
        eventImage{
          asset->{
          _id,
          url
          }
        },
        description,
        facebookUrl,
        categories,
        recurring,
      }`,
      )
      .then((data) => {
        setEventData(data[0]);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!eventData) return <Loading />;

  const dateFormatter = () => {
    const unixTime = Date.parse(eventData?.eventDate);
    const dateObject = new Date(unixTime);
    const date = `0${dateObject.getDate()}`.slice(-2);
    let dayString = norwegianDays[dateObject.getDay()];
    const month = norwegianMonths[dateObject.getMonth()];
    const minutes =
      dateObject.getMinutes() < 10
        ? `0${dateObject.getMinutes()}`
        : dateObject.getMinutes();
    if (eventData.recurring) {
      dayString = dayString.toLowerCase();
      return `Hver ${dayString} kl. ${dateObject.getHours()}:${minutes}`;
    }
    return `${dayString} ${date}. ${month} kl. ${dateObject.getHours()}:${minutes}`;
  };
  return (
    <EventsContainer>
      <Fade cascade damping={0.2} duration={1000} delay={100} triggerOnce>
        <BackButton onClick={history.goBack}>
          <FaArrowLeft size='0.8em' /> Tilbake
        </BackButton>
        <ImageBox>
          <EventImage src={eventData.eventImage.asset.url} />
        </ImageBox>
        <EventInfoContainer>
          {' '}
          <UnderlinedBoldTitle margin='0 0 22px 0'>
            {eventData.title}
          </UnderlinedBoldTitle>
          <DescriptionText margin='3px 10px 3px 0'>
            <b>Når:</b> {dateFormatter()}
          </DescriptionText>
          <DescriptionText margin='3px 10px 3px 0'>
            <b>Hvor:</b> {eventData.place}
          </DescriptionText>
        </EventInfoContainer>
        <DescriptionContainer>
          <BlockContent
            blocks={eventData.description}
            projectId={sanityClient.config().projectId}
            dataset={sanityClient.config().dataset}
          />
        </DescriptionContainer>
        {eventData.facebookUrl ? (
          <SocialContainer>
            <BoldTitle
              fontSize='18px'
              mobileMidFontSize='16px'
              mobileFontSize='16px'
              margin='0 0 15px 0'
            >
              Lenke til arrangementet
            </BoldTitle>
            <AwesomeButtonSocial
              type='facebook'
              href={eventData.facebookUrl}
              target='_blank'
              size='large'
            >
              Facebook
            </AwesomeButtonSocial>
          </SocialContainer>
        ) : (
          <></>
        )}
      </Fade>
    </EventsContainer>
  );
};

export default SingleEvent;
