/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import styled from 'styled-components';
import { BoldTitle } from 'styles/Text';
import MailLetter from './MailLetter';

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 30px;
  margin: 0 20px 40px 20px;
  border-radius: 5px;
  background-color: #f4f1f9;
  border: 1px solid ${({ theme }) => theme.lightPurple};
`;

const FormContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0 20px;
  @media (max-width: 576px) {
    margin: 10px 0;
  }
`;

const SuggestionText = styled.p`
  margin: 10px 0 0 0;
  font-weight: 600;
`;

const SuggestionForm = () => {
  return (
    <FlexContainer>
      <FormContainer>
        <BoldTitle margin='0'>Forslagsboks</BoldTitle>
        <a
          target='_blank'
          href='https://docs.google.com/forms/d/e/1FAIpQLSeKRE6H-BVmdV2t8gFLpz0UvZmPj1UxTl7tRxaPQlK6423aFA/viewform?usp=sf_link'
          rel='noreferrer'
        >
          <MailLetter />
        </a>
        <SuggestionText>Har du forslag til PSI?</SuggestionText>
        <SuggestionText>Trykk på brevet!</SuggestionText>
      </FormContainer>
    </FlexContainer>
  );
};

export default SuggestionForm;
