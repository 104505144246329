import React, { useEffect, useState } from 'react';
import sanityClient from 'sanityClient';
import GoodFeel from 'components/homepage/GoodFeel';
import Landing from 'components/homepage/Landing';
import SuggestionForm from 'components/homepage/SuggestionForm';
import styled from 'styled-components';
import Layout from 'styles/Layout';
import { Fade } from 'react-awesome-reveal';
import {
  norwegianDays,
  norwegianMonths,
} from 'components/eventpage/HelperLists';
import { SanityData } from 'types/types';
import Loading from 'components/Loading';

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
  align-items: flex-start;
  margin: 100px 0 50px 0;
  justify-content: center;
`;
const HomePage: React.FC = () => {
  const [landingData, setLandingData] = useState<SanityData>();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[(_type == "events" && priority) || (_type == "events" && dateTime(now()) < dateTime(eventDate))] |
        order(priority desc, dateTime(eventDate) asc) {
          slug,
          title,
          eventDate,
          priority,
          place,
          recurring,
          eventImage{
            alt,
            asset->{
            _id,
            url,
          },
        }
      }`,
      ) // Fetches and finds the nearest event in time
      .then((data) => {
        setLandingData(data[0]);
      });
  }, []);

  if (!landingData)
    return (
      <Layout>
        <Loading />
      </Layout>
    );

  const dateFormatter = () => {
    const unixTime = Date.parse(landingData.eventDate);
    const dateObject = new Date(unixTime);
    const date = `0${dateObject.getDate()}`.slice(-2);
    let dayString = norwegianDays[dateObject.getDay()];
    const month = norwegianMonths[dateObject.getMonth()];
    if (landingData.recurring) {
      dayString = dayString.toLowerCase();
      return `Hver ${dayString}`;
    }
    return `${dayString} ${date}. ${month}`;
  };

  return (
    <Fade cascade damping={0.2} duration={1000} delay={200} triggerOnce>
      <Layout>
        <Landing
          slug={landingData.slug.current}
          title={landingData.title}
          date={dateFormatter()}
          place={landingData.place}
          imgUrl={landingData.eventImage.asset.url}
          imgAltUrl={landingData.eventImage.alt}
        />
        <FlexContainer>
          <GoodFeel />
          <SuggestionForm />
        </FlexContainer>
      </Layout>
    </Fade>
  );
};

export default HomePage;
