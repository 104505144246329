import React from 'react';
import Navbar from 'components/Navbar';
import styled from 'styled-components';
import Footer from 'components/Footer';

interface LayoutProps {
  padding?: string;
  mobilePadding?: string;
}

const Body = styled.main`
  margin: 0;
  margin-top: 70px; // Height of navbar
  display: flex;
  min-height: 60vh;
  flex-direction: column;
  padding: ${(props: LayoutProps) =>
    props.padding || '0 10%'}; /* Flex wraps incorrectly if padding is 0 */
  @media (max-width: 768px) {
    padding: ${(props: LayoutProps) => props.mobilePadding || '0 2%'};
    min-height: 40vh;
  }
`;

const Layout: React.FC<LayoutProps> = ({
  children,
  padding,
  mobilePadding,
}) => {
  return (
    <>
      <Navbar />
      <Body padding={padding} mobilePadding={mobilePadding}>
        {children}
      </Body>
      <Footer />
    </>
  );
};

export default Layout;
