import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from 'pages/Routes';

const App: React.FC = () => {
  const script = document.createElement('script');
  script.async = true;
  script.text = `
  window.dataLayer = window.dataLayer || [];
  function gtag() { dataLayer.push(arguments); }
  gtag('js', new Date());

  gtag('config', 'G-XZ359HRH93');
  `;

  document.body.appendChild(script);

  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
};

export default App;
