import React from 'react';
import { Switch, Route } from 'react-router-dom';
import SingleEventPage from './SingleEventPage';
import EventPage from './EventPage';
import HomePage from './HomePage';
import AboutPage from './AboutPage';
import NewStudentPage from './NewStudentPage';
import GalleryPage from './GalleryPage';
import ScrollToTop from './ScrollToTop';

const Routes: React.FC = () => {
  return (
    <Switch>
      <>
        <ScrollToTop />
        <Route exact path='/' component={HomePage} />
        <Route path='/arrangementer/:slug' component={SingleEventPage} />
        <Route exact path='/arrangementer/' component={EventPage} />
        <Route path='/om-oss' component={AboutPage} />
        <Route path='/ny-student' component={NewStudentPage} />
        <Route path='/galleri' component={GalleryPage} />
      </>
    </Switch>
  );
};

export default Routes;
