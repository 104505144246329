import React from 'react';
import styled from 'styled-components';
import { AnchorText } from 'styles/Text';
import { FaMapMarked, FaFacebookF, FaFacebookMessenger } from 'react-icons/fa';
import sitLogo from '../assets/images/sit-logo.svg';

const FooterContainer = styled.div`
  margin-top: auto;
  display: flex;
  color: white;
  background-color: ${({ theme }) => theme.grey};
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 2% 5% 5% 5%;
`;

const FooterSegments = styled.div`
  display: flex;
  padding: 10px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const CenteredSegments = styled.div`
  text-align: left;
`;

const FooterTitle = styled.p`
  margin-top: 30px;
  font-weight: 600;
  font-size: 19px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const FooterText = styled.p`
  font-size: 15px;
  font-weight: 300;
  margin: 9px 0;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const FooterLink = styled.a`
  text-decoration: none;
  color: white;
  display: block;
  font-size: 15px;
  font-weight: 300;
  margin: 9px 0;

  @media (max-width: 768px) {
    font-size: 14px;
    margin: 16px 0;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const FooterLinkSocial = styled.a`
  text-decoration: none;
  color: white;
  font-weight: 300;
  margin: 0 20px 10px 0;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const Logo = styled.img`
  cursor: pointer;
  width: 90px;
`;

const NavbarMenu: React.FC = () => {
  return (
    <>
      <FooterContainer>
        <FooterSegments>
          <CenteredSegments>
            <FooterTitle>Besøksadresse</FooterTitle>
            <FooterText>Rom 12409, Dragvoll</FooterText>
            <FooterLink
              target='_blank'
              href='https://link.mazemap.com/LZTk5DfK'
              rel='noopener'
            >
              <FaMapMarked /> Mazemap
            </FooterLink>
            <FooterTitle>Samarbeidspartnere</FooterTitle>
            <a href='https://www.sit.no/' target='_blank' rel='noreferrer'>
              <Logo src={sitLogo} alt='sitLogo' />
            </a>
          </CenteredSegments>
        </FooterSegments>
        <FooterSegments>
          <CenteredSegments>
            <FooterTitle>Meny</FooterTitle>
            <FooterLink href='/arrangementer'>Arrangementer</FooterLink>
            <FooterLink href='/om-oss'>Om oss</FooterLink>
            <FooterLink href='/ny-student'>Ny student</FooterLink>
            <FooterLink href='/galleri'>Galleri</FooterLink>
          </CenteredSegments>
        </FooterSegments>
        <FooterSegments>
          <CenteredSegments>
            <FooterTitle>Kontakt</FooterTitle>
            <FooterLinkSocial
              target='_blank'
              href='https://www.facebook.com/psi.linjeforening/'
              rel='noopener'
              aria-label='fbButton'
            >
              <FaFacebookF size='1.6em' />
            </FooterLinkSocial>
            <FooterLinkSocial
              target='_blank'
              href='https://www.facebook.com/messages/t/fnkyfreud/'
              rel='noopener'
              aria-label='fbMessengerButton'
            >
              <FaFacebookMessenger size='1.6em' />
            </FooterLinkSocial>
            <FooterLink href='mailto:psi.linjeforening@gmail.com'>
              psi.linjeforening@gmail.com
            </FooterLink>
            <FooterLink href='mailto:psi.eventkom@gmail.com'>
              psi.eventkom@gmail.com
            </FooterLink>
            <FooterText>
              Utviklet av{' '}
              <AnchorText
                target='_blank'
                href='https://www.wictor.no'
                rel='noreferrer'
              >
                Wictor Zhao
              </AnchorText>
            </FooterText>
          </CenteredSegments>
        </FooterSegments>
      </FooterContainer>
    </>
  );
};

export default NavbarMenu;
