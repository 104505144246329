import { DefaultTheme } from 'styled-components';

declare module 'styled-components' {
  export interface DefaultTheme {
    brightPurple: string;
    lightPurple: string;
    purple: string;
    grey: string;
    whiteGrey: string;
    white: string;
    black: string;
    brightRed: string;
  }
}

export const defaultTheme: DefaultTheme = {
  brightPurple: '#CF4ECF',
  lightPurple: '#E9E4F3',
  purple: '#B989B3',
  grey: '#50514F',
  whiteGrey: '#F5F5F5',
  white: '#FFF',
  black: '#000',
  brightRed: '#f16a70',
};
