import SingleEvent from 'components/eventpage/SingleEvent';
import React from 'react';
import Layout from 'styles/Layout';

const SingleEventPage: React.FC = () => {
  return (
    <Layout padding='0 20%'>
      <SingleEvent />
    </Layout>
  );
};

export default SingleEventPage;
