import React from 'react';
import styled from 'styled-components';
import { BounceLoader } from 'react-spinners';

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10vh;
  text-align: center;
`;

const LoadingText = styled.div`
  text-align: center;
  margin-top: 15px;
  color: ${({ theme }) => theme.brightPurple};
`;

const Loading: React.FC = () => {
  return (
    <>
      <LoaderContainer>
        <BounceLoader color='#CF4ECF' />
      </LoaderContainer>
      <LoadingText>Laster inn..</LoadingText>
    </>
  );
};

export default Loading;
