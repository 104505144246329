import React, { useEffect, useState } from 'react';
import sanityClient from 'sanityClient';
import styled from 'styled-components';
import { GoodFeelData } from 'types/types';
import { BoldTitle, SubTitle, UnderlinedBoldTitle } from 'styles/Text';
import { weekNumber } from 'weeknumber';

const LandingContainer = styled.div`
  flex: 1;
  min-width: 50%;
  margin: 0 20px 40px 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding: 30px 30px;
  border-radius: 5px;
  justify-content: center;
  background-color: ${({ theme }) => theme.lightPurple};
  @media (max-width: 576px) {
    padding: 10px 20px;
    margin: 50px 0;
  }
  @media (max-width: 768px) {
    width: 90%;
  }
`;

const TextContainer = styled.div`
  flex: 0 1 100%;
  margin: 10px;
  text-align: center;
`;

const TextSpan = styled.div`
  height: 200px;
  padding: 20px;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
`;

const SplitContainer = styled.div`
  flex: 1 0 25%;
  margin: 20px;
  padding: 20px;
  background-color: ${({ theme }) => theme.whiteGrey};
  border-radius: 5px;
  text-align: center;
  min-width: 200px;

  @media (max-width: 576px) {
    margin: 10px 10px;
    padding: 15px;
  }
`;

const Meme = styled.img`
  display: block;
  max-width: 100%;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  min-width: 200px;
`;

const MemeText = styled.p`
  font-style: italic;
  margin-top: 2px;
  font-size: 14px;
`;

const GoodFeel: React.FC = () => {
  const [goodFeelData, setGoodFeelData] = useState<GoodFeelData>();

  const currentWeekNumber = weekNumber(new Date());

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "goodFeel"] | order(index desc) {
          quote,
          index,
          meme {
            alt,
            asset->{
            _id,
            url,
            alt,
          },
        }
      }`,
      ) // Fetches and finds the nearest event in time
      .then((data) => {
        const currentWeekMeme = data.filter(
          (item) => item.index === currentWeekNumber,
        );
        if (currentWeekMeme.length > 0) {
          setGoodFeelData(currentWeekMeme[0]);
        } else {
          setGoodFeelData(data[0]);
        }
      });
  }, [currentWeekNumber]);

  if (!goodFeelData) return <div />;

  return (
    <LandingContainer>
      <TextContainer>
        <BoldTitle>Feelgood-hjørnet</BoldTitle>
        <SubTitle>Uke {currentWeekNumber}</SubTitle>
      </TextContainer>
      <SplitContainer>
        <UnderlinedBoldTitle
          margin='0 0 20px 0'
          fontSize='20px'
          mobileMidFontSize='16px'
          mobileFontSize='16px'
          left='50%'
          marginLeft='-20px'
          size='2px'
        >
          Ukens meme
        </UnderlinedBoldTitle>
        <Meme src={goodFeelData.meme.asset.url} alt={goodFeelData.meme.alt} />
        <MemeText>{goodFeelData.meme.alt}</MemeText>
      </SplitContainer>
      <SplitContainer>
        <UnderlinedBoldTitle
          margin='0 0 10px 0'
          fontSize='20px'
          mobileMidFontSize='16px'
          mobileFontSize='16px'
          left='50%'
          marginLeft='-20px'
          size='2px'
          color='#CF4ECF'
        >
          Ukens sitat
        </UnderlinedBoldTitle>
        <TextSpan>
          <SubTitle fontWeight='600'>{goodFeelData.quote}</SubTitle>
        </TextSpan>
      </SplitContainer>
    </LandingContainer>
  );
};

export default GoodFeel;
