import React from 'react';
import styled from 'styled-components';
import { UnderlinedBoldTitle, DescriptionText, SubTitle } from 'styles/Text';
import { Link } from 'react-router-dom';

interface LandingProps {
  slug: string;
  title: string;
  date: string;
  place: string;
  imgUrl: string;
  imgAltUrl: string;
}

const LandingContainer = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
`;

const CardLink = styled(Link)`
  text-decoration: none;
`;

const TextContainer = styled.div`
  flex-grow: 1;
  flex-basis: calc(45% - 20px);
  display: flex;
  flex-direction: column;
  margin: 10px;
  min-width: 200px;
  justify-content: center;
`;

const ImageContainer = styled.div`
  flex-grow: 1;
  flex-basis: calc(55% - 22px);
  margin: 10px;
  min-width: 300px;
`;

const LandingImage = styled.img`
  display: block;
  max-width: 100%;
  border-radius: 5px;
`;

const GreyAnchorText = styled.p`
  margin: 10px 0 0 0;
  color: ${({ theme }) => theme.grey};
  font-size: 16px;
  display: inline-block;

  &:hover {
    transition: 0.5s all;
    color: rgba(0, 0, 0, 0.4);
  }
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const Landing: React.FC<LandingProps> = ({
  slug,
  title,
  date,
  place,
  imgUrl,
  imgAltUrl,
}) => {
  return (
    <CardLink to={`/arrangementer/${slug}`}>
      <LandingContainer>
        <TextContainer>
          <SubTitle>Neste arrangement:</SubTitle>
          <UnderlinedBoldTitle margin='10px 10px 30px 0' mobileFontSize='25px'>
            {title}
          </UnderlinedBoldTitle>
          <DescriptionText>
            <b>Når:</b> {date}
          </DescriptionText>
          <DescriptionText>
            <b>Hvor:</b> {place}
          </DescriptionText>
          <GreyAnchorText>Se mer {'>'}</GreyAnchorText>
        </TextContainer>
        <ImageContainer>
          <LandingImage src={imgUrl} alt={imgAltUrl} />
        </ImageContainer>
      </LandingContainer>
    </CardLink>
  );
};

export default Landing;
