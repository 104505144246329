import React, { useCallback } from 'react';
import styled from 'styled-components';
import { NavLink, useHistory } from 'react-router-dom';
import Navbar, { ThemeProps } from '@acto/react-navbar';
import psilogo from '../assets/images/psilogo.svg';

const NavbarContainer = styled.nav`
  width: 100%;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  height: 70px;
  position: absolute;
  margin-bottom: 70px;
`;

const navTheme: ThemeProps = {
  mainColor: '#B989B3',
  backgroundColor: '#F5F5F5',
  menuBgColor: '#F5F5F5',
};

const StyledNavLink = styled(NavLink)`
  color: ${({ theme }) => theme.grey} !important;
  font-size: 18px;
  white-space: nowrap;
  margin: 0 20px !important;
  display: inline-block;
  position: relative;

  &.active::after {
    text-decoration: underline;
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    color: ${({ theme }) => theme.purple} !important;
    border-radius: 2px;
  }

  &:hover {
    opacity: 1 !important;
  }

  &:hover::after {
    text-decoration: underline;
    opacity: 1;
    transform: scale(1);
    color: ${({ theme }) => theme.purple} !important;
  }

  &::after {
    position: absolute;
    left: 0;
    bottom: -10px;
    content: '';
    display: inline-block;
    width: 100%;
    border-top: 2px solid;
    opacity: 0;
    transition: opacity 0.35s, transform 0.55s;
    transform: scale(0, 1);
    border-radius: 2px;
  }

  @media (max-width: 768px) {
    margin: 5px 0 5px 0 !important;
    padding: 15px !important;
    font-weight: bold;
    &::after {
      bottom: 0;
    }
  }

  @media (max-width: 820px) {
    font-size: 16px;
  }
`;

const Logo = styled.img`
  cursor: pointer;
  width: 220px;
  z-index: 99;
`;

const rightLinks = (
  <>
    <StyledNavLink to='/arrangementer'>Arrangementer</StyledNavLink>
    <StyledNavLink to='/om-oss'>Om oss</StyledNavLink>
    <StyledNavLink to='/ny-student'>Ny student</StyledNavLink>
    <StyledNavLink to='/galleri'>Galleri</StyledNavLink>
  </>
);

const insertStyle = (css) => {
  const style = document.createElement('style');
  style.setAttribute('type', 'text/css');
  style.innerHTML = css;
  document.head.appendChild(style);
  return css;
};

const NavbarMenu: React.FC = () => {
  const history = useHistory();
  const handleOnClick = useCallback(() => history.push('/'), [history]);

  const navBrand = <Logo src={psilogo} onClick={handleOnClick} alt='Logo' />;

  insertStyle('.navbarCss {\n box-shadow: none !important;');

  return (
    <>
      <NavbarContainer>
        {' '}
        {/* <Logo src={ psilogo} onClick={handleOnClick} alt='Logo' /> */}
        <Navbar
          className='navbarCss'
          brand={navBrand}
          theme={navTheme}
          rightLinks={rightLinks}
          aria-label='navbar'
        />
      </NavbarContainer>
    </>
  );
};

export default NavbarMenu;
