import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

  body {
    margin: 0;
    padding: 0;
    min-width: 320px;
    line-height: 1.7;
    background: ${({ theme }) => theme.whiteGrey};
    font-family: 'Montserrat', sans-serif,   Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

`;

export default GlobalStyle;
