import AboutContent from 'components/aboutpage/AboutContent';
import Loading from 'components/Loading';
import React, { useEffect, useState } from 'react';
import sanityClient from 'sanityClient';
import Layout from 'styles/Layout';
import { SanityData } from 'types/types';

const NewStudentPage: React.FC = () => {
  const [newStudentData, setNewStudentData] = useState<SanityData[]>();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "newStudent"] | order(index) {
        title,
        description,
        index,
        "pdfURL": pdf.asset->url
      }`,
      )
      .then((data) => {
        setNewStudentData(data);
      });
  }, []);

  if (!newStudentData)
    return (
      <Layout>
        <Loading />
      </Layout>
    );
  return (
    <Layout padding='0 10%' mobilePadding='0'>
      <AboutContent
        listOfTitles={newStudentData.map((x) => x.title)}
        listOfDescription={newStudentData.map((x) => x.description)}
        pdfURL={newStudentData.map((x) => x.pdfURL)}
      />
      {/* Send list of all titles */}
    </Layout>
  );
};

export default NewStudentPage;
